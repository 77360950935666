//Defino objeto user, donde cargaré los datos del usuario.
// Coloco toda la info proveniente del servidor
module.exports = function User() {
    this.class = "user";
    this.loged = false;
    this.id;//id del usuario
    this.email;
    this.premium;//información del estado de pago
    this.availables_artificial_groups={};//Listas artificiales de especies (custom y master)
    this.settings = {"setting_id":"",
                    "tagImg":{"favorite":"",
                            "notes":""},
                    "tagSp":{"favorite":"",
                            "notes":""},
                    "selectedLists":{},
                    "selectedTaxa":{"k":[],
                                    "p":[],
                                    "c":[],
                                    "o":[],
                                    "sf":[],
                                    "f":[],
                                    "g":[]}};
    user.anotherSettings={};


    this.checkSession = function(){
        console.log("Chequeando datos de sesión...")
        return new Promise(function(resolve, reject){
            $.ajax({
                type: "POST",
                url: "./A/checkSession.php",
                data: {"data": "checkSession"},
                success: function(result) {
                    resultado = JSON.parse(result);
                    //console.log(resultado)
                    resolve(resultado);
                },
                error: function(result) {
                    alert('error, no se pudo chequear el estado de sesión');
                    reject(result)
                }
            });
        });
    }

    //MAIN: Función para enviar seteos de usuario)...incluyendo listas de especies
    this.setUserSettings = function(fullFilters) {
        let artificialGroups = fullFilters.A_spArtificialGroups
        let taxonomicGroups = fullFilters.A_spTaxonomicGroups
        //Envío array de ids de grupos artificiales a insertar
        return new Promise(function(resolve, reject){
            let data = new FormData();
            data.append("FCAG", JSON.stringify(artificialGroups));
            data.append("FCTG", JSON.stringify(taxonomicGroups));
            // for (var pair of data.entries()) {
            //     console.log(pair[0]+ ', ' + pair[1]); 
            // }
            //Envío los datos
            $.ajax({
                type: "POST",
                url: "./A/userSettings.php",
                data: data,
                contentType: false,
                processData: false,
                success: function(result) {
                    //console.log(result)
                    resolve(JSON.parse(result));
                },
                error: function(result) {
                    alert('error en la actualización de la configuración de filtros');
                    reject(result);
                }
            });
        });
        
    };

    //Método para cargar el objeto con toda la info del usuario proveniente del servidor
    this.loadUserData = function (userData){
        console.log("Cargando datos de usuario\n")
        this.loged = userData.logged;
        this.id=userData["user_id"];//id del usuario
        this.premium=userData["premium"];//información del estado de pago
        this.availables_artificial_groups=userData["availables_artificial_groups"];
        //SETEOS DEL USUARIO///////////////////////////////////////////////////////////////
        this.settings = userData["settings"];
        this.email= userData["email"];
        this.anotherSettings=userData["anotherSettings"];
    }

        //MAIN: Función para enviar seteos de usuario)...incluyendo listas de especies
        this.changeTeme = function(teme) {
            console.log("Cambiando el tema de estudio a "+teme)
            return new Promise(function(resolve, reject){
                $.ajax({
                    type: "POST",
                    url: "./A/userSettings.php",
                    data: {"data": "ChangeTeme",
                        "teme": teme},
                    success: function(result) {
                        resultado = JSON.parse(result);
                        //console.log(resultado)
                        resolve(resultado);
                    },
                    error: function(result) {
                        alert('error, no se pudo chequear el estado de sesión');
                        reject(result)
                    }
                });
            });
            
        };

        //Función para obtener los detalles de suscripciones de un usuario
        this.getSuscriptionDetails = function(){
            console.log("Consultando suscripciones del usuario...")
            return new Promise(function(resolve, reject){
                $.ajax({
                    type: "POST",
                    url: "./A/userSuscriptions.php",
                    data: {"type":"get"},
                    success: function(result) {
                        resultado = JSON.parse(result);
                        resolve(resultado);
                    },
                    error: function(result) {
                        alert('error, no se pudo consultar las suscripciones del usuario');
                        reject(result)
                    }
                });
            });
        }

        //Función para obtener los detalles de suscripciones de un usuario
        this.changeSuscriptionStatus = function(action,subscriptionID){
            console.log("Cambiando suscripciones del usuario...")
            return new Promise(function(resolve, reject){
                $.ajax({
                    type: "POST",
                    url: "./A/userSuscriptions.php",
                    data: {"type":"change",
                            "action":action,
                            "subscriptionID":subscriptionID},
                    success: function(result) {
                        resultado = JSON.parse(result);
                        resolve(resultado);
                    },
                    error: function(result) {
                        alert('error, no se pudo consultar las suscripciones del usuario');
                        reject(result)
                    }
                });
            });
        }
}
