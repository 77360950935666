// functionFlow = ["index"];//Temporal para almacenar el flujo de funciones ejecutadas en js

require ("./css/main.css")
require ("./css/studyArea.css")
require ("./css/wikimedia.css")
require ("./externals/bootstrap.min.css")
require ("./css/navbar.css")



// require('jquery');//No me lo toma a tiempo para cargar los webcomponents
// require('bootstrap');//Automáticamente importa sus dos dependencias(jquery y pooper)

//Cargo los módulos
let GeneralFunctions = require ("./general_functions.js")
let User = require ("./Classes/User.js")
let NavBar = require ("./Classes/ModuloNavbar.js")
// let Version = require ("./Classes/Version.js")

//Cargo los wc (más escuchadores?)
require ('./webComponents/my-navbar.js');
require ('./webComponents/my-login.js');
require ('./webComponents/my-paypalModal.js');
require ('./webComponents/my-userDetailsModal.js');


require ('./webComponents/my-registry.js');
require ('./webComponents/my-modalLogin.js');
require ('./webComponents/my-modalRestartKey.js');
require ('./webComponents/my-navfooter.js');



//Al final de la carga chequeo el estado de session del usuario y disparo varias funciones
$(document).ready(function() {
    //Consulto la versión de la página y recargo con cache busting si no es correcta
    // var actualVersion = new Version();
    // if($("my-navbar #version").attr("value")===undefined){
    //     location.reload(true);
    // }else{
    //     if($("my-navbar #version").attr("value")!==actualVersion){
    //         location.reload(true);
    //     }
    // }

    //Consulto que no esté recibiendo ningún parámetro get
    if(window.location.search.substr(1)!==""){
        function getSearchParameters() {
            var prmstr = window.location.search.substr(1);
            return prmstr != null && prmstr != "" ? transformToAssocArray(prmstr) : {};
        }
        
        function transformToAssocArray( prmstr ) {
            var params = {};
            var prmarr = prmstr.split("&");
            for ( var i = 0; i < prmarr.length; i++) {
                var tmparr = prmarr[i].split("=");
                params[tmparr[0]] = tmparr[1];
            }
            return params;
        }
        
        params = getSearchParameters();
   
        if(Object.keys(params).length===2){
            if(Object.keys(params)[0]==='id' && Object.keys(params)[1]==='tk'){//Algunos click desde facebook me devuelven un get con este parámetro
                //Completo el formulario
               $("#restoreKeyForm #restoreKeyemail").val(params.id)
               //limpio los parámetros get
               // var myNewURL = "reestablecimientoDeContraseña";//the new URL
               // window.history.pushState("object or string", "Title", "/" + myNewURL );
               //Abro el modal de recuperación
               $("#restoreKeyModal").modal()
           }
        }else{
            //Recargo la página completa (debería unicamente borrar los parámetros GET
            // window.location.replace("./index.html");)
        }

       
        
    }
    


    console.log("Página cargada...disparo funcionalidades")
    // Escuchadores
    // require ('./general_listeners.js');
    //Instancio variables importantes como globales (evito usar var, pues me convierte la variable en local)
    gf = new GeneralFunctions();
    navBar = new NavBar();
    user = new User();
    user.checkSession()
    .then(function(resultado){
        //Cargo los datos del usuario en "user"
        console.log("Cargando datos de usuario\n")
        user.loadUserData(resultado);
        user.actualPage="index";
        //Cambio el color y las opciones del navBar
        navBar.navBarSessionStatus(resultado);
        //Oculto el botón de filtros del navbar
        // document.getElementById("showFilters").setAttribute("style","display:none")
        // if(resultado["logged"]==1){//USUARIO LOGUEADO
        //     //Cargo los datos del usuario en "user"
        //     console.log("Cargando datos de usuario\n")
        //     user.loadUserData(resultado);
        //     user.actualPage="index";
        //     //Verifico el estado de premium
        //     if(resultado["premium"]==1){
        //         //Oculto modal de paypal
        //         $("#paypalModal").modal("hide");
        //     }else if(resultado["premium"]==0){
        //         $("#paypalModal").modal('show');
        //     }
        // }else{
        //     //Oculto modal de paypal
        //     $("#paypalModal").modal("hide");
        // }
        // //Agrego información al userDetailsModal
        // $("#userDetailsModal #userEmail").text(user.email);

        //Renderizo el botón de paypal y cargo las funcionalidades correspondientes
        // paypal.Buttons({
        //     style: {
        //       //   shape: 'pill',
        //       //   color: 'gold',
        //       //   layout: 'horizontal',
        //       //   label: 'paypal'
        //         shape: 'rect',
        //         color: 'gold',
        //         layout: 'vertical',
        //         label: 'subscribe'
        //     },
        //     createSubscription: function(data, actions) {
        //       return actions.subscription.create({
        //         /* Creates the subscription */
        //         plan_id: 'P-2LN70237T22518324MBNOPTA'
        //       });
        //     },
        //     onApprove: function(data, actions) {
        //       //alert(data.subscriptionID); // You can add optional success message for the subscriber here
        //       //console.log(data);
        //       //Custom
        //       gf.paypalAproved(data).then(function(data){
        //           if(data===1){
        //               alert("Registro de pago exitoso.")
        //               user.checkSession()
        //                   .then(function(resultado){
        //                       navBar.navBarSessionStatus(resultado);
        //                       if(resultado["logged"]==1){//USUARIO LOGUEADO
        //                           user.loadUserData(resultado);
        //                           // user.actualPage="index";
        //                       }
        //                   })
        //           }else if(data===0){
        //               alert("Ha ocurrido un error en el almacenamiento del pago. Comuníquese con nosotros (estudiavisu) para solucionarlo")
        //           }else{
        //               alert(data)
        //           }
        //       })
        //     }
        // }).render('#paypal-button-container-P-2LN70237T22518324MBNOPTA'); // Renders the PayPal button

    })
});

