const { uniqueSort } = require("jquery");

//defino la clase
class Login extends HTMLElement {
    // Implementación del componente
    constructor() {
        super();
    }
    connectedCallback () {
		console.log("cargando elemento login")
        // let shadowRoot = this.attachShadow({mode: 'open'});
        // shadowRoot.innerHTML = `
        this.innerHTML = ` <div id="signIn" class="container" style="display:block">
        <!--<div class="row">
            <div class="col-sm-12">
                <h1>Ingreso</h1>		
            </div>
        </div>-->
    
        <div class="row">	
        <!--<div class="col-sm-12 col-md-6 col-lg-6">-->
            <div class="col-12">
                <h4>Acceda a su cuenta</h4>
                <form id="logInForm" action="" method="post">                           	
                    <div class="form-group">									
                        <input type="email" class="form-control input-lg" name="email" maxlength="127" placeholder="Correo electrónico" >        
                    </div>							
                    <div class="form-group" style="margin-bottom: 8px;">        
                        <input type="password" class="form-control input-lg" maxlength="20" name="password" placeholder="Contraseña" >       
                    </div>
                    <div style="text-align: end; margin-bottom: 8px;"><a id="restoreKey" href="#" title="Crear una cuenta">Olvidé mi contraseña</a></div>									    
                        <button id="logInSubmit" type="submit" class="btn btn-success btn-block">Entrar</button>
                </form>	
                							

            </div>		
            <!--<div class="col-sm-12 col-md-6 col-lg-6">-->
                <div class="col-12">
                <div id=registration_status></div>
                <!--<<h3>Registrese</h3>-->
                <div id="loginAlert" style="color: red; margin-top: 0.5rem; display:none">Hubo un problema con el correo o la contraseña. Verifica que ambos son correctos. Si continúan los problemas 
                <span class="pointer" onclick="window.open('./contactForm.html', 'Contacto', 'width=600,height=900');" style="color: blue;">escríbenos</span>
                </div>
                <hr>
                <p>¿Aún no posees cuenta? <a class="showRegistry" href="#" title="Crear una cuenta">Regístrate</a></p>								
                </div>
        </div>
    </div>`;
        
          }
}

//defino el elemento custom (etiqueta, clase)
window.customElements.define("my-login",Login)

// $('#signIn').on('click', '.showRegistry',function (e) {
$('my-loginmodal').on('click', '.showRegistry',function (e) {
	$("#signIn").hide();
	$("#registry").show();
});

//Enviar formulario de inicio de sessión
$('my-loginmodal').on('click',"#logInSubmit",function(e){
    e.preventDefault();
    var form = $("#logInForm");
    let validation = gf.validateLogingRegistryForm(form[0])
	if(!validation){//Si la validación del formulario es incorrecta, salgo
        return false
	}
    navBar.login(form)
		.then(function(resultado){
            //Cambio el color y las opciones del navBar
            //debugger
            navBar.navBarSessionStatus(resultado);
            if(resultado["logged"]==1){//USUARIO LOGUEADO
                if(user.actualPage==="index"){
                    //actualizo usuario
                    user.loadUserData(resultado);
                    user.actualPage="index";
                }else if(user.actualPage==="test"){
                    user.loadUserData(resultado);
                    user.actualPage="test"
                    //Debería pedir una nueva tanda de imágenes
                    filters.queryDataSetByFilterSettings();// Consulto base de datos y cargo todo

                    /*Momentaneo hasta pasar todo a practicar-visu.html"*/
                    // var pathname = window.location.pathname;
                    // const lastItem = pathname.substring(pathname.lastIndexOf('/') + 1)
                    // if(lastItem==='practicar-visu.html'){
                    //     // user.actualPage="test";
                    // }else{
                        // user.actualPage="test-paypal";
                        //Muestro u oculto el nabvar de paypal según el estado de la cuenta
                        // gf.paypalStatus(resultado);
                        			//Aumento en 1 el contador de tandas pedidas
                        //tandaNumero=tandaNumero+1;//Esto lo actualizo dentro de filters.queryDataSetByFilterSettings();
                        if(tandaNumero>1){//Evaluo si el usuario es premium
                            resultado["logged"]=user.loged;
                            resultado["premium"]=user.premium;
                            resultado["answersCounter"]=tandaNumero;
                            gf.paypalStatus(resultado);
                        }
                    // }
                    /*Fin de temporal*/



                }else if(user.actualPage==="study"){
                    user.loadUserData(resultado);
                    user.actualPage="study";
                    //Actualizar las listas y galería (no se si me convence actualizar galería automáticamente)
                    //Escondo el muro hasta obtener respuesta
                    // $("#speciesByFilters .gallery.mw-gallery-traditional").hide();
                    //Solicito datos y espero
                    dataSet.newDataSet(12, "fullSequential", pageNumber=1)
                        .then(function(resultado){
                            //Cargo la respuesta en el imageDataSet y en el muro
                            console.log("Cargando el dataSet de galería");
                            dataSet.loadData(resultado)
                            //Cargo el muro de imágenes
                            console.log("Cargando muro de imágenes de galería");
                            wall.makeWall(dataSet, allSpecies=true, wallId="speciesByFilters");
                            if(dataSet.imagesIds.length>=12){//Cargo la paginación (Esto debería ir siempre de cargar mediante filtros)
                                pagination.updatePaginationDOM()//Cargo la botonera
                                pagination.pageNumberUpdate(pagination.activePageNumber)//Marco la página 1
                            }
                            //muestro el muro
                            $("#speciesByFilters .gallery.mw-gallery-traditional").show();
                        })
                    //2) Obtengo las listas del usuario (y las listas maestras)
                    //actualizo directamente en el objeto user
                    // tables.getFullUserLists()
                    // .then(function(result){
                    //     console.log(result);
                        actualNames=[];//Variable para tener un control de los nombres presentes en la lista
                        actualList={"selectedSpecies":[]};//Variable para manejar la info de las listas activas (especies existentes y a agregar)
                    //     //gf.fillListTable(data);
                        tables.fillListTable();//Lleno la tabla con la info de user.artificial_groups...

                    // });
                }
                //Oculto el modal de legueo/registro
                $("#loginModal").modal('hide')
            }else{
                //Esta alerta la podría imprimir en el modal de logueo
                // alert("Hubo un problema en el ingreso. Revise usuario y contraseña.")
                $("#loginAlert").show();
            }
            //Sea cual fuera el resultado de inicio de sessión, actualizo el botón del área temática actual
            if(user.settings.teme==1){
                $("#areaTematica").text("(Biología)")
            }else if(user.settings.teme==2){
                $("#areaTematica").text("(Geología)")
            }else if(user.settings.teme==3){
                $("#areaTematica").text("(Paleonología)")
            }else if(user.settings.teme==4){
                $("#areaTematica").text("(Histología)")
            }
        });
    });

    //Si hago algún cambio en cualquier input del logueo, oculto el mensaje de error (si estuviera visible)
    $('my-loginmodal').on('keyup',"input",function(e){
        $("#loginAlert").hide();
    })

    //Restauración de contraseña
    $("my-loginmodal").on("click","#restoreKey", function(){
        var email = prompt("Ingrese el correo con el cual se ha registrado");
        let emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
          
        if (email==null || email==""){  
            alert("El correo no puede estar vacío");  
            return false;  
        }
        else if (!emailRegex.test(email)){
            alert("Formato de correo no aceptado");
            return false;
        }

        //Si el correo es correcto, lo envío para avisar por mail
        gf.sendMailToReseKey(email).then(function(){
            alert("Revisa tu correo para continuar con el restablecimiento de la clave")
        });



    })