

//import DropdownMenu from '../Classes/classDropdownMenu';

//defino la clase
class PayPalModal extends HTMLElement {
    // Implementación del componente
    constructor() {
        super();
    }
    connectedCallback () {
    console.log("cargando paypal modal")
    this.innerHTML = `
    <div id="paypalModal" class="modal fade" data-keyboard="false" data-backdrop="static" style="display:none; padding-top: 50px;">
    <div class="modal-dialog">
      <div class="modal-content" style="padding-left: 20px; padding-right: 20px; padding-bottom: 20px;">
         <div class="modal-header">						
           <a class="navbar-brand ml-auto mr-auto" href="./"><img id="navbarBrand" src="./img/icons/ev.svg" width="30" height="30" alt=""></a>
        </div>
          <div style="margin-bottom: 20px;font-weight: 600; text-align: center;"><div style="font-size: 20px;">Suscríbete por solo 2 €/mes </div><div id="textAnexoToPayPal"></div></div>
          <div id="paypal-button-container-x"></div>
          <div style="border: solid 1px gray;overflow-y: auto;max-height: 150px;text-align: justify;border-radius: 3px;font-size: 13px;">
            <!--
            <div>A partir del mes de Abril es necesaria una suscripción paga para poder usar el área de práctica.</div>
            <div>La suscripción incluirá 1 semana de prueba gratuita y podrás darte de baja en cualquier momento.</div>
            -->
            <div style="font-weight: bolder;">¿Por qué cobramos?</div>
            <div>Si bien nos gustaría poder ofrecer esta herramienta en forma libre y gratuita, el mantenimiento de la misma requiere de ciertos gastos y, principalmente, una inversión de tiempo cada vez mayor.</div>
            <div>Si te suscribes a la plataforma nos darás un empujoncito para dedicarle aún más tiempo y poder seguir agregando información y funcionalidades.</div>
          </div>
          <div style="margin-top: 10px; text-align: center; font-size: 13px;">Si tienes algún inconveniente con el pago, <a href="./contactForm.html" target="_blank" style="font-weight: 600;" title="Póngase en contacto con nosotros">escríbenos</a></div>

        </div>
      </div>
    </div>
  </div>
      `;
        }
}

//defino el elemento custom (etiqueta, clase)
window.customElements.define("my-paypalmodal",PayPalModal)

