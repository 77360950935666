const { uniqueSort } = require("jquery");

//FUNCIONES
module.exports = function NavBar() {
//Método para modificar el estado de la navbar según el status del usuario
    this.navBarSessionStatus = function(resultado){
        if(resultado["logged"]==1){
            console.log("AJAX-Inicio de sesión exitoso. Cambiando estado de navbar...\n");
            //color de fondo del navbar
            if(resultado["premium"]==1){
                document.getElementsByTagName("nav")[0].setAttribute("style","background-color: #D4AF37")
                //document.getElementsByTagName("nav")[0].setAttribute("style","background-color: green")

            }else{
                document.getElementsByTagName("nav")[0].setAttribute("style","background-color: green")
            }
            //Actualizo botones dependientes de sessión
            var session_items=document.getElementsByClassName("session");
            for(i=0;i<session_items.length;i++){
                // session_items[i].setAttribute("style","display:block");
                $(session_items[i]).show();

            }
            //Desabilito los que lo requieran
            // var disabled_items=document.getElementsByClassName("disabled");
            // for(i=0;i<disabled_items.length;i++){
            //     disabled_items[i].setAttribute("style","display:block;color: #c24949;");
            // }
            
            var noSession_items=document.getElementsByClassName("noSession");
            for(i=0;i<noSession_items.length;i++){
                // noSession_items[i].setAttribute("style","display:none");
                $(noSession_items[i]).hide();
            }
            //Cierro el div de logueo
            if(document.getElementById("signIn_registry")!=null){
                logDiv=document.getElementById("signIn_registry").setAttribute("style","display:none");
            }
            //Coloco correo del usuario (recortado a 7 max) en el navbar
            var usermail = resultado.email.slice(0, 7);
            document.getElementById("UsuarioNavbar").innerHTML=usermail+"..."
            document.getElementById("UsuarioNavbar").setAttribute("title",resultado.email);

        }else{//Si no hay sessión activa
            //alert("Para utilizar todas las funcionalidades debe iniciar sesión")
            //color de fondo del navbar
            document.getElementsByTagName("nav")[0].setAttribute("style","background-color: grey")
            //Quito botones dependientes de sessión
            var noSession_items=document.getElementsByClassName("noSession");
            for(i=0;i<noSession_items.length;i++){
                // noSession_items[i].setAttribute("style","display:block");
                $(noSession_items[i]).show();
            }
            var session_items=document.getElementsByClassName("session");
            for(i=0;i<session_items.length;i++){
                // session_items[i].setAttribute("style","display:none");
                $(session_items[i]).hide();
            }
            //Aseguro que el navbar no tenga ningun mail previo
            document.getElementById("UsuarioNavbar").innerHTML="Info";
            document.getElementById("UsuarioNavbar").setAttribute("title","usuario");

        }
        //Visibilizo el navbar
        $(document.getElementsByTagName("nav")[0]).show()
        
        // //Muestro u oculto el nabvar de paypal según el estado de la cuenta
        // //Solo en test área
        // if(user.actualPage==='test'){
        //     gf.paypalStatus(resultado);
        // }
    };

    //Método de logueo
    this.login = function (form){
        console.log('Enviando datos de logueo...');
        // functionFlow.push("navBar.login()")
        return new Promise(function(resolve, reject){
            //Agrego un token necesario en Laravel para evitar el bloqueo por CSRF
            // $.ajaxSetup({headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')}});
            $.ajax({
                type: "POST",
                url: './A/userLogin.php',
                data: form.serializeArray(),
                success: function(result) {
                    //console.log(result);
                    result = JSON.parse(result);
                    resolve(result)
                },
                error: function(result) {
                    alert('Error en la solicitud de inicio de sesión');
                    reject(result);
                }
            });  
        })
    }

    //Módulo de registro
    this.registry = function (form){
        // functionFlow.push("navBar.registry()")
        console.log("Enviando datos de registro");
        return new Promise(function(resolve, reject){
        $.ajax({
            type: "POST",
            url: "./A/userCreateAccount.php",
            data: form.serializeArray(),
            success: function(result) {
                //console.log(result);
                resultado = JSON.parse(result);
                console.log(resultado);
                resolve(resultado)
            },
            error: function(result) {
                alert('Se ha producido un error en el procesamiento de los datos. Intente registrarse nuevamente. \
                Si continúan los problemas, comuníquese con el administrador');
                reject()
            }
        });
    });
    }

    //Método de deslogueo
    this.logout = function(){
        // functionFlow.push("navBar.logout()")
        //Agrego un token necesario en Laravel para evitar el bloqueo por CSRF
        $.ajaxSetup({headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')}});
        $.ajax({
            type: "POST",
            url: './A/logout.php',
            data: {"action": "logout"},
            success: function(result) {
                console.log(result);
                result = JSON.parse(result);
                console.log("AJAX-solicitud de cierre de sesión exitoso'...\n");
                if(result["logged"]==false){
                    //alert("Cierre de sessión exitoso")
                    //color de fondo del navbar
                    document.getElementsByTagName("nav")[0].setAttribute("style","background-color: grey")
                    //Quito botones dependientes de sessión
                    var noSession_items=document.getElementsByClassName("noSession");
                    for(i=0;i<noSession_items.length;i++){
                        // noSession_items[i].setAttribute("style","display:block");
                        $(noSession_items[i]).show();

                    }
                    var session_items=document.getElementsByClassName("session");
                    for(i=0;i<session_items.length;i++){
                        // session_items[i].setAttribute("style","display:none");    
                        $(session_items[i]).hide();                    
                
                    }
                    document.getElementById("UsuarioNavbar").innerHTML="Info";
                    document.getElementById("UsuarioNavbar").setAttribute("title","Usuario");

                }else{
                    alert("Cierre de sessión incorrecto. La misma permanece abierta")
                }
                //Sea cual fuera el resultado de cierre de sessión, actualizo el botón del área temática actual
                if(user.settings.teme==1){
                    $("#areaTematica").text("(Biología)")
                }else if(user.settings.teme==2){
                    $("#areaTematica").text("(Geología)")
                }else if(user.settings.teme==3){
                    $("#areaTematica").text("(Paleonología)")
                }else if(user.settings.teme==4){
                    $("#areaTematica").text("(Histología)")
                }
            },
            error: function(result) {
                alert('Error en la solicitud de cierre de sesión');
            }
        });  
    }
}
