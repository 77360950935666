

//import DropdownMenu from '../Classes/classDropdownMenu';

const general_functions = require("../general_functions");

//defino la clase
class UserDetailsModal extends HTMLElement {
    // Implementación del componente
    constructor() {
        super();
    }
    connectedCallback () {
    console.log("cargando paypal modal")
    this.innerHTML = `
    <style>
      table, td, th {
        border: 1px solid black;
      }
      
      table {
        width: 100%;
        border-collapse: collapse;
      }


    </style>
    <div id="userDetailsModal" class="modal fade" data-keyboard="false" data-backdrop="static" style="display:none;">
    <div class="modal-dialog">
      <div class="modal-content" style="padding: 20px;">
         <div class="modal-header" style="padding-left: 0rem; padding-top: 0px; padding-bottom: 8px;">						
          <div class="modal-title" style="display: inline-block; font-size: 24px;"><b>Suscripciones</b></div>
          <div style="display: inline-block"> <button type="button" class="close" data-dismiss="modal" aria-hidden="true" style="padding-right: 0rem">X</button> </div>
        </div>
        <div id="userEmail" style="margin-bottom: 20px;font-weight: 600; font-size: 20px;">...</div>
        <div style="text-align:left;">
          <div style="overflow-x:auto; height: 130px; overflow:auto;">
          <table id="suscriptionsDataTable">
            <tr>
              <th>Plan</th>
              <th>Costo</th>            
              <th>ID suscripción</th>
              <th>Inicio</th>
              <th>estado</th>
              <th>opciones*</th>
            </tr>
            <tbody>
              <tr>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
              </tr>
            </tbody>
          </table>
          </div>
          <hr>
          <div id="suscriptionModalInfo">
          <li>*Si posees cuenta en <a href="https://www.paypal.com/es/webapps/mpp/home" target="_blank">PayPal</a>, puedes suspender/cancelar tu suscripción en forma inmediata desde dicha plataforma</li>
          <li>Si tienes alguna duda o problema con las suscripciones, <a href="./contactForm.html" target="_blank">escríbenos</a> y lo resolveremos cuanto antes (recuerda colocar el id de suscripción en el mensaje)</li>
          </div>
          <div id="suscriptionToChangeID" style="display:none; background-color: yellow" value="" action="">
          <hr>
          <div id="suscriptionToChangeIDtext"></div>
          <button class="pointer" id="aceptuScriptionToChange">SI</button>
          <button class="pointer" id="rejectScriptionToChange">NO</button>
          </div>

        </div>
      </div>
    </div>
  </div>
      `;
        }
}

//defino el elemento custom (etiqueta, clase)
window.customElements.define("my-userdetailsmodal",UserDetailsModal)

//Escucho el botón de reactivación
$("#suscriptionsDataTable").on('click',".suscriptionReactive",function(e){
  // alert("Botón de pausa presionado")
  var suscription_id = $(this).attr("value")
  $("#suscriptionToChangeIDtext").text("Está seguro que desea REACTIVAR la suscripción de ID: "+suscription_id)
  $("#suscriptionToChangeID").attr("value",suscription_id)
  $("#suscriptionToChangeID").attr("action","reactivar")
  $("#suscriptionToChangeID").show()
  $("#suscriptionModalInfo").hide()
});

//Escucho el botón de pausa
$("#suscriptionsDataTable").on('click',".suscriptionPause",function(e){
  // alert("Botón de pausa presionado")
  var suscription_id = $(this).attr("value")
  $("#suscriptionToChangeIDtext").text("Está seguro que desea PAUSAR la suscripción de ID: "+suscription_id)
  $("#suscriptionToChangeID").attr("value",suscription_id)
  $("#suscriptionToChangeID").attr("action","pausar")
  $("#suscriptionToChangeID").show()
  $("#suscriptionModalInfo").hide()

});

$("#suscriptionsDataTable").on('click',".suscriptionCancel",function(){
  // alert("Botón de baja presionado")
  var suscription_id = $(this).attr("value")
  $("#suscriptionToChangeIDtext").text("Está seguro que desea CANCELAR definitivamente la suscripción de ID: "+suscription_id)
  $("#suscriptionToChangeID").attr("value",suscription_id)
  $("#suscriptionToChangeID").attr("action","cancelar")
  $("#suscriptionToChangeID").show()
  $("#suscriptionModalInfo").hide()

});

$("#aceptuScriptionToChange").on('click',function(){
  alert("Enviando cambios a la base de datos. La confirmación del mismo puede demorar algunas horas.");
  var suscription_id =  $("#suscriptionToChangeID").attr("value")
  var action = $("#suscriptionToChangeID").attr("action")
  user.changeSuscriptionStatus(action, suscription_id)
  .then(function(data){
    //Cargo la tabla con las suscripciones
    gf.loadSuscriptionsTable(data);
    //si todo ok, cierro y actualizo el modal de userDetails
    $("#suscriptionToChangeIDtext").text("")
    $("#suscriptionToChangeID").attr("value","")
    $("#suscriptionToChangeID").attr("action","")
    $("#suscriptionToChangeID").hide()
    $("#suscriptionModalInfo").show()

  });
});

$("#rejectScriptionToChange").on('click',function(){
  // alert("Botón de baja presionado")
  $("#suscriptionToChangeIDtext").text("")
  $("#suscriptionToChangeID").attr("value","")
  $("#suscriptionToChangeID").attr("action","")
  $("#suscriptionToChangeID").hide()
  $("#suscriptionModalInfo").show()
});