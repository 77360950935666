//Coloco las funciones accesorias como métodos de un objeto
module.exports = function GeneralFunctions() {
    this.deleteChild = function (container) { 
        //e.firstElementChild can be used. 
        var child = container.lastElementChild;  
        while (child) { 
            container.removeChild(child); 
            child = container.lastElementChild; 
        } 
    }
    
    //FUNCION AUXILIAR: Para realizar deep copy de mis atributos
    this.deepCopy = function(obj){
        var newObj = JSON.parse(JSON.stringify(obj));
        return newObj;
    }
    
    //Manejadores de botones grises
    // 1) Función para crear los botónes grises, en el div señalado, de una clase dada y con los datos de cierto objetoJS
    this.GrayButtonsDOMupdate = function(edit=false, divId=null, clase=null, data=null, toDelete=null){
        //debugger
        // functionFlow.push("gf.GrayButtonsDOMupdate()");
        var activeGroups = data
        var activeGroupsChanges = toDelete
        console.log("GRAYBUTTONS:  actualizando el DOM de "+divId+"");
        var divElement = document.getElementById(divId);
        //Borro lo que hubiera previamente en el DOM
        divElement.innerHTML="";
        if(activeGroups!=undefined){
            var VNK = Object.keys(activeGroups);
            if(VNK.length>0){
                if(edit==false){//Si no está para editar, los coloco como texto simple
                    // var vn = activeGroups[VNK[0]]["name"]+"("+activeGroups[VNK[0]]["languaje"]+")";
                    // var vn = activeGroups[VNK[0]]["name"]+"("+activeGroups[VNK[0]]["languaje"]+")";
                    var vn = activeGroups[VNK[0]]["name"];

                    for(i=1;i<VNK.length;i++){
                        // vn = vn+"; "+activeGroups[VNK[i]]["name"]+"("+activeGroups[VNK[i]]["languaje"]+")";
                        vn = vn+"; "+activeGroups[VNK[i]]["name"];

                    }
                    divElement.innerHTML="<strong>"+vn+"</strong>";
                }else if(edit==true){//Coloco los nombres como botones
                    for(i=0;i<VNK.length;i++){
                        var button = document.createElement('button');
                        button.setAttribute("id","selected_"+VNK[i]);
                        button.setAttribute("class",clase);
                        //Agrego el nombre al botón
                        button.innerHTML = activeGroups[VNK[i]]["name"];
                        //Cambio el color a rojo si se encuentra en el area de eliminación
                        if(activeGroupsChanges["deleted"].includes(parseInt(VNK[i]))){
                            console.log("GRAYBUTTONS: Cambiando a rojo por estar en el area de eliminación")
                            button.setAttribute("style","background-color: darkred");
                        }
                        divElement.appendChild(button);
                    }
                }
            }
        }
    }
    
    //Función para actualizar los botónes de pertenencia de la especie actual a las distintas listas de especies
    this.GrayButtonsDOMupdateTaxonomic = function(filterObject=null){
        //1) Si hay algo previo, lo borro
        console.log("Actualizo botones de selección checkboxes seleccionados")
        // var parent = document.getElementById(buttonsElementId);
        var parent = document.getElementById("filter_TaxonomicGroupsSelected");
        this.deleteChild(parent);
        //A partir del nodo parent (seleccionado por su id en un listener externo), procedo a carga todo el html
        if(filterObject.A_spTaxonomicGroups.activeTaxonomicGroups!=undefined){//Me aseguro de tener al menos 1 botón
            var ranks=["k","p","c","o","sf","f","g"];
            var colors=["#909090","#A0A0A0","#B0B0B0","#C0C0C0","#D0D0D0","#E0E0E0","#F0F0F0"]
            for(j=0;j<ranks.length;j++){
                var arrays = filterObject.A_spTaxonomicGroups.activeTaxonomicGroups[ranks[j]]
                //console.log(arrays);
                //Cargo botones si hay hay algún elemento que cargar
                if(arrays!=null){
                    var selectedKeys = Object.keys(arrays);
                        for(i=0;i<selectedKeys.length;i++){
                            var button = document.createElement('button');
                            button.setAttribute("id","selected_"+arrays[selectedKeys[i]].id);
                            button.setAttribute("class","actualCategory");
                            button.setAttribute("style","background-color:"+colors[j]);
                            //Si está presente en el area pendiente de eliminación, lo coloreo de rojo oscuro
                            if(filterObject.A_spTaxonomicGroups.specieTaxonomicGroupsChanges["deleted"].includes(arrays[selectedKeys[i]].id)){
                                button.setAttribute("style","background-color: darkred");
                            }
                            //Agrego el nombre al botón
                            button.innerHTML = arrays[selectedKeys[i]].text;
                            parent.appendChild(button);
                        }
                }
            }
        }else{//Si no estaba definido, lo defino para poder agregar botones
            filterObject.A_spTaxonomicGroups.activeTaxonomicGroups={};//Esto no tiene sentido. Simplemente...no hago nada
        }
    }
        
    
    //Función para manejar la eliminación de botones grises
    //El color del botón estará ligado a si está en el área de deleción, de adición o ninguna
    this.GrayButtonsChangeStatus = function(element, attribute){
        console.log("GRAYBUTTONS: Agregando al area de eliminación en el objeto principal")
        var id =  parseInt(element.id.split('_')[1]);
        if(attribute["deleted"].includes(id)){//si el id ya está incluido, lo elimino
            const index = attribute["deleted"].indexOf(id);
            if (index > -1) {
                attribute["deleted"].splice(index, 1);
            }
            //Y cambio el color
        }else{//si no lo incluye, lo agrego
            attribute["deleted"].push(id);
        }
    
    }
    
    //Función para aleatorizar un array (La uso para generar las opciones al azar para cada imagen)
    //https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array/18650169#18650169
    this.shuffle = function (array) {
        // alert("caller is " + this.shuffle.caller);
        // console.log("caller of gf.shufle is: " +arguments.callee.caller.name)
        // console.log("caller of gf.shufle is: " +console.trace())
        // console.trace()
        var currentIndex = array.length, temporaryValue, randomIndex;
        // While there remain elements to shuffle...
        while (0 !== currentIndex) {
            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;
            // And swap it with the current element.
            temporaryValue = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = temporaryValue;
        }
        return array;
    }
        //Código para ver la distancia entre dos palabras
    //tomado de: http://jsfiddle.net/DelightedD0D/SWX77/39/
    //enlazado desde: https://stackoverflow.com/questions/18050932/detect-differences-between-two-strings-with-javascript
    //Da la Levenshtein distance
    this.levDist = function(s, t) {
        var d = []; //2d matrix
        // Step 1
        var n = s.length;
        var m = t.length;
        if (n == 0) return m;
        if (m == 0) return n;
        //Create an array of arrays in javascript (a descending loop is quicker)
        for (var i = n; i >= 0; i--) d[i] = [];
        // Step 2
        for (var i = n; i >= 0; i--) d[i][0] = i;
        for (var j = m; j >= 0; j--) d[0][j] = j;
        // Step 3
        for (var i = 1; i <= n; i++) {
            var s_i = s.charAt(i - 1);
            // Step 4
            for (var j = 1; j <= m; j++) {
                //Check the jagged ld total so far
                if (i == j && d[i][j] > 4) return n;
                var t_j = t.charAt(j - 1);
                var cost = (s_i == t_j) ? 0 : 1; // Step 5
                //Calculate the minimum
                var mi = d[i - 1][j] + 1;
                var b = d[i][j - 1] + 1;
                var c = d[i - 1][j - 1] + cost;
                if (b < mi) mi = b;
                if (c < mi) mi = c;
                d[i][j] = mi; // Step 6
                //Damerau transposition
                if (i > 1 && j > 1 && s_i == t.charAt(j - 2) && s.charAt(i - 2) == t_j) {
                    d[i][j] = Math.min(d[i][j], d[i - 2][j - 2] + cost);
                }
            }
        }
        // Step 7
        return d[n][m];
    }

    //Función para dar color a partir de un valor
    //fuente: http://jsfiddle.net/jongobar/sNKWK/
    this.getColor = function(value, transparency='50%'){
        //value from 0 to 1
        var hue=((1-value)*120).toString(10);
        //return ["hsl(",hue,",100%,50%)"].join("");
        return ["hsl(",hue,",100%,",transparency,")"].join("");

    }

    //Método para validar formularios de registro y/o ingreso
    this.validateLogingRegistryForm = function(form){  
        let email=form.email.value;  
        let password=form.password.value;
        let emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
          
        if (email==null || email==""){  
          alert("El correo no puede estar vacío");  
          return false;  
        }
        else if (!emailRegex.test(email)){
            alert("Formato de correo no aceptado");
            return false;
        }
        else if(password.length<6){  
          alert("La clave debe tener al menos 6 dígitos");  
          return false;  
        }        
        else if(password.length>20){  
            alert("La clave debe ser menor a 20 dígitos");  
            return false;  
          }
        else {
          return true;
        }
    }

    //Método para enviar correo electrónico del usuario y comenzar proceso de registro
    this.sendMailToReseKey = function (email){
        // functionFlow.push("navBar.registry()")
        datos = {"email":email}
        console.log("Enviando correo para restablecimiento de contraseña");
        return new Promise(function(resolve, reject){
        $.ajax({
            type: "POST",
            url: "./A/userAccountResetKey.php",
            data: datos,
            success: function(result) {
                //console.log(result);
                resultado = JSON.parse(result);
                console.log(resultado);
                resolve(resultado)
            },
            error: function(result) {
                alert('Se ha producido un error en el procesamiento de los datos. Intente registrarse nuevamente. \
                Si continúan los problemas, comuníquese con el administrador');
                reject()
            }
        });
    });
    }

    //Método para insertar nueva clave en el usuario
    this.newKey = function (form){
        // functionFlow.push("navBar.registry()")
        datos = {"email":params.id, "password":form.password.value, "token":params.tk}
        console.log("Enviando nueva contraseña");
        return new Promise(function(resolve, reject){
        $.ajax({
            type: "POST",
            url: "./A/userAccountResetKeyFinish.php",
            data: datos,
            success: function(result) {
                //console.log(result);
                resultado = JSON.parse(result);
                console.log(resultado);
                resolve(resultado)
            },
            error: function(result) {
                alert('Se ha producido un error en el procesamiento de los datos. Intente registrarse nuevamente. \
                Si continúan los problemas, comuníquese con el administrador');
                reject()
            }
        });
    });
    }

    this.validateInputAlphanumeric = function(inputForm){  
        //let email=form.email.value;  
        let text=inputForm.value;
        // let emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
        // let regex = new RegExp("^[a-zA-Z0-9.,/ $@()]+$");
        // let regex = new RegExp("^[a-zA-Z0-9]");
        let regex = new RegExp("^[a-zA-Z0-9áéíóúü()_-\\s]+$");

        // if (text==null || text==""){  
        //   alert("El correo no puede estar vacío");  
        //   return false;  
        // }
        if (regex.test(text)){
            return true;
        }
        // else if(password.length<6){  
        //   alert("La clave debe tener al menos 6 dígitos");  
        //   return false;  
        // }        
        // else if(text.length>32){  
        //     alert("La nombre debe ser menor a 32 dígitos");  
        //     return false;  
        //   }
        else {
          alert("Solo se admiten caracteres alphanuméricos y - _ ( )");
          return false;
        }
    }

    //Método para abrir una nueva ventana
    //https://developer.mozilla.org/es/docs/Web/API/Window/open#Position_and_size_features
    this.openRequestedSinglePopup = function (windowObjectReference, PreviousUrl, strUrl, strWindowName) {
        if(windowObjectReference == null || windowObjectReference.closed) {
            windowObjectReference = window.open(strUrl, strWindowName,
                 "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=600,height=400,addressbar=no");
          } else if(PreviousUrl != strUrl) {
            windowObjectReference = window.open(strUrl, strWindowName,
              "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=600,height=400,addressbar=no");
            /* if the resource to load is different,
               then we load it in the already opened secondary window and then
               we bring such window back on top/in front of its parent window. */
            windowObjectReference.focus();
          } else {
            windowObjectReference.focus();
          };
        PreviousUrl = strUrl;
        /* explanation: we store the current url in order to compare url
            in the event of another call of this function. */
    }

    //Función para ejecutar cuando no se obtiene una imágen desde el servidor
    this.imageNotFound = function(imageElement,container="carousel"){
        if(imageElement.getAttribute('request')=='0'){
            imageElement.setAttribute('request','1')
            //Envío un ajax con el id de la imágen no cargada (tabién con el id del usuario)
            image_id=imageElement.getAttribute('image_id');
            user_id=user.id;
            actualPage=user.actualPage;
            //Almaceno los datos de las imágenes faltantes
            $.ajax({
                asynch:false,
                type: "POST",
                url: "./A/addImagesNotFound.php",
                //Envío los datos actuales de especies setted (me faltaría favoritos y notas)
                data: {"image_id": image_id,
                        "user_id": user_id,
                        "actualPage":actualPage,},
                success: function(result) {
                    console.log(result)
                    console.log("AJAX-Marcado exitoso de imagen faltante en la DB");
                 },
                error: function(result) {
                    alert('error en el envío de imágenes faltantes');

                }
            });    
        }
        //Cargo la imagen de error
        imageElement.setAttribute('src','./img/icons/imageNotFound-120px.png')
        //Cargo las imágenes con una clase dedicada para despues borrarlas
        let toDelete = $(imageElement).parent()//.addClass("toDeleteItem");
        //Ejecuto la limpieza después de ejecutada esta función contenedora 
        //(por más que tenga que hacerlo varias veces si hay muchas imágenes mal)
        //Por ahora solo en el carousel del area de práctica
        if(container==="carousel"){
            this.deleteCarouselImage(toDelete); //Elimino y reinicio el carousel
        }else if(container==="wall"){
            //¿Que hago????
        }
        
    }

    //Función para borrar una imágen del carousel si no está disponible 
    //ATENCION: (devo chequear como afecta las respuestas)
    this.deleteCarouselImage = function(toDelete){
        //1) Identifico las imágenes no disponibles
        //alert("Eliminadas "+toDelete.length+" por errores en la carga de la imágen")
        if(toDelete.length>0){
            for(var j=0; j<toDelete.length; j++){
                var imgToDelete = $(toDelete.children()[j]).attr("img_id");
                for( var i = 0; i < carousel.keys.length; i++){
                    if ( carousel.keys[i] === imgToDelete) {
                         carousel.keys.splice(i, 1); 
                        }
                    }
              }
        //Recargo el carousel?(parece funcionar)
        carousel.loadDOMCarousel(miniatures=false, eye=true, favorite=true, specieImagesWall=false, notes=true);
        }
    }

    //Para chequear que el texto ingresado en la nueva lista/edición sea correcto
    this.checkListName = function(previousNames, newName, alertText){
        if ( previousNames.includes(newName)) {
            document.getElementById(alertText).innerHTML="El nombre ya existe. Ingrese uno diferente."
            document.getElementById(alertText).setAttribute("style","color:red")
        }else if(newName == "" || newName == null){
            document.getElementById(alertText).innerHTML="Ingrese algún texto válido para su nueva lista."
            document.getElementById(alertText).setAttribute("style","color:red")
        } else {
            return true;
            
        }
    }

    //Función para preparar los modales de edición de listas y especies en listas
    this.passDataToModal = function(element, species_ids_array=null){
    //   if(element.parentElement.getAttribute("specie-name")!==null){
    //     // list_id=element.getAttribute("list-id");
    //     ev.listas.actualList.list_id=element.getAttribute("list-id");
    //     document.getElementById("listNameToBlock").innerHTML=element.parentElement.getAttribute("list-name")
    //     document.getElementById("specieNameToEditDelete").innerHTML=element.parentElement.getAttribute("specie-name")
    //     document.getElementById("blockSpecie").setAttribute("list-id",ev.listas.actualList.list_id)
    //     document.getElementById("blockSpecie").setAttribute("specie-id",element.parentElement.getAttribute("specie-id"))
      if(species_ids_array!==null){//Si el id de especies no es nulo ni vacío, opero
        // $('my-listspeciesdatatable .selectedSpeciesNumber').text(species_ids_array.length+" especies")
        // $('my-listspeciesdatatable .actualListName').text(ev.listas.actualList.list_name)
        // document.getElementById("specieNameToEditDelete").innerHTML=species_ids_array.length+" especies"
        // document.getElementById("listNameToBlock").innerHTML=ev.listas.actualList.list_name
      }else{
        // list_id=element.parentElement.getAttribute("list-id");
        ev.listas.actualList.list_id=element.parentElement.getAttribute("list-id");
        document.getElementById("listNameToEditDelete").innerHTML=element.parentElement.getAttribute("list-name")
        document.getElementById("listNameToEdit").innerHTML=element.parentElement.getAttribute("list-name")
      }
    }

    this.changeTutorialModalStatus = function(page){
        //return new Promise(function(resolve, reject){
        $.ajax({
                asynch:false,
                type: "POST",
                url: "./A/userSettings.php",
                //url: 'getImageSet',
                //Envío los datos actuales de especies setted (me faltaría favoritos y notas)
                data: { "action": "blockTutorialModal",
                        "page": page},
                success: function() {
                    //console.log(result)
                    //list_data = JSON.parse(result);
                    console.log("Tutorial ocultado permanentemente");
                    //return(images_set_data)
                    // resolve(list_data);
    
                },
                error: function() {
                    alert('error en el bloqueo del tutorial');
                }
            });
    }

    this.addSelectedSpecies = function(actualList){
        return new Promise(function(resolve, reject){
        //Envío los ids de las especies que aún no están en la lista
        species_ids_array = $.grep(actualList["selectedSpecies"], function(value) {
            return $.inArray(value, actualList["specie_ids"]) < 0;
        });
        $.ajax({
                asynch:false,
                type: "POST",
                url: "./A/addNewSpeciesToList.php",
                //url: 'getImageSet',
                //Envío los datos actuales de especies setted (me faltaría favoritos y notas)
                data: { "action": "addNewSpeciesToList",
                        "list_id": actualList["list_id"],
                        "species_ids_array":species_ids_array},
                success: function(result) {
                    console.log("status adición especies a listas: "+result)
                    //list_data = JSON.parse(result);
                    //console.log("Especies agregadas a "+actualList["list_name"]);
                    //return(images_set_data)
                    resolve(result);
    
                },
                error: function() {
                    alert('error en la adición de nuevas especies a la lista actual');
                    // reject();
    
                }
            });
        });
    }

    //Cargo datos de la lista a donde agregaré las especies
  this.saveMultipleSpeciesArtificialGroups = function(specie_id, listName, list_id=null){
        //Según el tab en el que me encuentre, tomaré distinto set de especies para enviar
        var controlTab = ""//Variable para controlar el tab desde el que se envió la consulta (por si llegara a haber un cambio)
        if($(".nav-item.nav-link.active").attr("id")=="nav-lists-tab"){//muro listas
            ev.listas.toSendList["selectedSpecies"] = ev.listas.actualList.selectedSpecies
            controlTab = "lists"
        }else if($(".nav-item.nav-link.active").attr("id")=="nav-galery-tab"){//muro galería
            ev.listas.toSendList["selectedSpecies"] = ev.repaso.selectedSpecies
            controlTab = "repaso"
        }else if($(".nav-item.nav-link.active").attr("id")=="nav-search-tab"){//muro search
            ev.listas.toSendList["selectedSpecies"] = ev.busqueda.selectedSpecies
            controlTab = "busqueda"

        }
        // var loadedSpecies = gf.getCheckedSpecies()//con esto cargo las especies en "ev.listas.toSendList["selectedSpecies"]"
		//reviso que haya al menos una especie a enviar
		// if(loadedSpecies){
        if(ev.listas.toSendList["selectedSpecies"].length>0){
            var x = confirm("Está a punto de agregar "+ev.listas.toSendList["selectedSpecies"].length+" especies a la lista "+listName)
            // var x = confirm("Está a punto de agregar "+loadedSpecies.length+" especies a la lista "+listName)
            if(x){
                dropDownMenuSpecieDetail.addNewListToDB(specie_id, listName, list_id=list_id)//Agrego una lista sin species
					.then(function (newList) {
                        ev.listas.toSendList.list_id=newList["id"];
                        ev.listas.toSendList.listName=newList["name"];
                        gf.addSelectedSpecies(ev.listas.toSendList)//Agrego array de especies
                        .then(function(){
                            tables.updateTables();//Actualizo tablas y demás
                            //Actualizo datasets (que me permitirán actualizar los botones grises al abrir el carousel)
                            for(var i=0; i<ev.listas.toSendList["selectedSpecies"].length; i++){
                                //No se que dataset estoy utilizando, asi que los actualizo todos (si corresponde)
                                var sp_id=ev.listas.toSendList["selectedSpecies"][i];
                                if (typeof dataSet !== 'undefined') {
                                    if(dataSet.speciesArtificialGroups[sp_id]!==undefined){
                                      console.log(" -dataSet.")
                                      dataSet.speciesArtificialGroups[sp_id][newList["id"]]={id: newList["id"], name: newList["name"], user_id: "0"}
                                    }
                                  }
                                  if (typeof dataSetList !== 'undefined') {
                                    if(dataSetList.speciesArtificialGroups[sp_id]!==undefined){
                                      console.log(" -dataSetList.")
                                      dataSetList.speciesArtificialGroups[sp_id][newList["id"]]={id: newList["id"], name: newList["name"], user_id: "0"}
                                    }
                                  }
                                  if (typeof dataSetSearch !== 'undefined') {
                                    if(dataSetSearch.speciesArtificialGroups[sp_id]!==undefined){
                                      console.log(" -dataSetSearch.")
                                      dataSetSearch.speciesArtificialGroups[sp_id][newList["id"]]={id: newList["id"], name: newList["name"], user_id: "0"}
                                    }
                                  }
                                // dataSet.speciesArtificialGroups[1087726][92]={id: selectedArtificialGroup, name: listName, user_id: "11"}
                                // dataSet.speciesArtificialGroups[1087726][92]={id: selectedArtificialGroup, name: listName, user_id: "11"}
                                // dataSet.speciesArtificialGroups[1087726][92]={id: selectedArtificialGroup, name: listName, user_id: "11"}
                            }

                            // Actualizo tooodos los dropdowns menús
                            // Con esto lleno el objeto contenedor de las listas
                            dropDownMenuSpecieDetail.loadDrpdown ("Listas de especies", user.availables_artificial_groups, "NADA");
                            // Con esto cargo los dropdowns de las listas
                            $(".SpecieDetailDropdownMenu").each(function(e){dropDownMenuSpecieDetail.updateDropdownDOM('', newGroup=true, masterOptions=true, customOptions=true, element=this)})
                            //Limpio y Deseleccionando el tab que corresponda
                            ev.listas.toSendList={"selectedSpecies":[]};
                            if(controlTab==="lists"){
                                // $(':checkbox').prop('checked', false);
                                $('#specieListWall :checkbox').prop('checked', false);//Reseteo las imágenes clickeadas
                                $('my-listspeciesdatatable :checkbox').prop('checked', false);//Reseteo las filas clickeadas
                            }else if(controlTab==="repaso"){
                                $('#speciesByFilters :checkbox').prop('checked', false);//Reseteo
                            }else if(controlTab==="busqueda"){
                                $('#searchResultsWall :checkbox').prop('checked', false);//Reseteo
                            }
                            $("#multipleSpleciesListBarrSelectorTable").hide()//Oculto el selector múltiple
                            //Me falta actualizar los botónes grises del carousel

                            //Actualizo tablas y demás
                            //console.log("Actualizo las tablas de listas")
                            // tables.getFullUserLists()
                            //     .then(function(data){
                            //         console.log(data);
                            //         //Actualizo tooodos los dropdowns menús
                            //         //Con esto lleno el objeto contenedor de las listas
                            //         dropDownMenuSpecieDetail.loadDrpdown ("Listas de especies", user.availables_artificial_groups, "NADA");
                            //         //Con esto cargo los dropdowns de las listas
                            //         $(".SpecieDetailDropdownMenu").each(function(e){dropDownMenuSpecieDetail.updateDropdownDOM('', newGroup=true, masterOptions=true, customOptions=true, element=this)})
                            //         console.log("Actualizando tablas listas y especies")
                            //         ev.listas.actualList["list_id"]=ev.listas.toSendList.list_id//Coloco el id de la lista a actualizar en forma manual...mejorar
                            //         tables.updateTables();
                            //         //Limpio y Deseleccionando todo
                            //         ev.listas.toSendList={}
                            //         $(':checkbox').prop('checked', false);
                            //         $("#multipleSpleciesListBarrSelector").hide()//Oculto el selector múltiple
                            //     });
                        })
                    });
            }  
        }else{
            alert("Debe seleccionar al menos una especie para crear y cargar la lista.")
			//Limpio y Deseleccionando todo
            ev.listas.toSendList={}
            $(':checkbox').prop('checked', false);
            $("#multipleSpleciesListBarrSelectorTable").hide()//Oculto el selector múltiple
        }
    }


    if($(".nav-item.nav-link.active").attr("id")=="nav-lists-tab"){//muro listas
        $('#specieListWall :checkbox').prop('checked', c);
    }else if($(".nav-item.nav-link.active").attr("id")=="nav-galery-tab"){//muro galería
        $('#speciesByFilters :checkbox').prop('checked', c);
    }else if($(".nav-item.nav-link.active").attr("id")=="nav-search-tab"){//muro search
        $('#searchResultsWall :checkbox').prop('checked', c);
    }

    //Mantengo actualizado los checkboxes con el objeto contenedor (y entre si en el caso de galerys-tabla)
    this.specieCheckboxClickEvent = function (table=false){
        //Según que tab se encuentre abierto, leo los checkboxes correspondientes
        console.log("Obteniendo especies checked")
        if($(".nav-item.nav-link.active").attr("id")=="nav-lists-tab"){//muro listas
            if(table){//Si selecciono desde la tabla, cargo el objeto y actualizo la galería
                var x = $('my-listspeciesdatatable input[class=checkbox-species]:checked')
                ev.listas.actualList.selectedSpecies=[]//Reseteo las especies seleccionadas en el area de listas
                $('#specieListWall :checkbox').prop('checked', false);//Reseteo las imágenes clickeadas
                for(var j=0;j<x.length;j++){
                    var sp_id=x[j].getAttribute("specie-id");
                    ev.listas.actualList.selectedSpecies.push(sp_id)
                    //Reclickeo en las miniaturas
                    $('#specieListWall #cbox_'+sp_id).prop('checked', true);//recclickeo las imágenes
                }
            }else{//Si selecciono desde la galería, cargo el objeto y actualizo la tabla
                var x = $('#specieListWall input[type=checkbox]:checked')//miniaturas checkeadas
                ev.listas.actualList.selectedSpecies=[]//Reseteo las especies seleccionadas en el area de listas
                $('my-listspeciesdatatable :checkbox').prop('checked', false);//Reseteo las filas clickeadas
                for(let i=0;i<x.length;i++){
                    sp_id=x[i].getAttribute("value")
                    ev.listas.actualList.selectedSpecies.push(sp_id)
                    //Reclickeo en las filas
                    $('my-listspeciesdatatable #checkbox_'+sp_id).prop('checked', true);//recclickeo las filas
                }
            }
            //Controlo visibilidad de la barra
            if(ev.listas.actualList.selectedSpecies.length>0){
                $(".multipleSpeciesBarrSelector").show();
            }else{
                $(".multipleSpeciesBarrSelector").hide();
            }
        }else if($(".nav-item.nav-link.active").attr("id")=="nav-galery-tab"){//muro galería
            //Cargo los ids de las especies seleccionadas en el tab de repaso
            var x = $('#speciesByFilters input[type=checkbox]:checked')
            ev.repaso.selectedSpecies=[]//Reseteo las especies seleccionadas en el area de repaso
            for(let i=0;i<x.length;i++){
                sp_id=x[i].getAttribute("value")
                //console.log(img_id)
                ev.repaso.selectedSpecies.push(sp_id)
            }
            //Controlo visibilidad de la barra
            if(ev.repaso.selectedSpecies.length>0){
                $("#multipleSpleciesListBarrSelectorTable").show()//Hago visible el seleccionador múltiple y de listas
            }else{
                $("#multipleSpleciesListBarrSelectorTable").hide()//Hago visible el seleccionador múltiple y de listas
            }
        }else if($(".nav-item.nav-link.active").attr("id")=="nav-search-tab"){//muro search
            var x = $('#searchResultsWall input[type=checkbox]:checked')
            ev.busqueda.selectedSpecies=[]//Reseteo las especies seleccionadas en el area de busqueda
            for(let i=0;i<x.length;i++){
                sp_id=x[i].getAttribute("value")
                //console.log(img_id)
                ev.busqueda.selectedSpecies.push(sp_id)
            }
            //Controlo visibilidad de la barra
			if(ev.busqueda.selectedSpecies.length>0){
				$("#multipleSpleciesListBarrSelectorTable").show()//Hago visible el seleccionador múltiple y de listas
			}else{
				$("#multipleSpleciesListBarrSelectorTable").hide()//Hago visible el seleccionador múltiple y de listas
			}
        }
    };


    //Campturo los ids de las especies chequeadas
    // this.getCheckedSpecies=function(){
    //     console.log("Obteniendo especies checked")
    //     if($(".nav-item.nav-link.active").attr("id")=="nav-lists-tab"){//muro listas
    //         var x = $('#specieListWall input[type=checkbox]:checked')
    //     }else if($(".nav-item.nav-link.active").attr("id")=="nav-galery-tab"){//muro galería
    //         var x = $('#speciesByFilters input[type=checkbox]:checked')
    //     }else if($(".nav-item.nav-link.active").attr("id")=="nav-search-tab"){//muro search
    //         var x = $('#searchResultsWall input[type=checkbox]:checked')
    //     }else{
    //         x=[];
    //     }
    //     // ev.listas.toSendList.list_id=list_id;
    //     ev.listas.toSendList.selectedSpecies=[]
    //         for(let i=0;i<x.length;i++){
    //             img_id=x[i].getAttribute("value")
    //             console.log(img_id)
    //     ev.listas.toSendList.selectedSpecies.push(img_id)
    //     }
    //     if(ev.listas.toSendList["selectedSpecies"].length===0){
    //         return false;//Si no hay especies a cargar, salgo
	// 	}else{
    //         return true;
    //     }
    // }

    // this.getCheckedSpeciesInTable=function(){
    //     console.log("Obteniendo especies checked de la tabla")
    //     var x = $('my-listspeciesdatatable input[class=checkbox-species]:checked')
    //     ev.listas.speciesTable.selectedSpeciesIds=[]//Limpio
    //     for(var j=0;j<x.length;j++){
    //         ev.listas.speciesTable.selectedSpeciesIds.push(x[j].getAttribute("specie-id"))
    //     }
    //     // ev.listas.toSendList.list_id=list_id;
    //     if(ev.listas.speciesTable.selectedSpeciesIds.length===0){
    //         return false;//Si no hay especies a cargar, salgo
	// 	}else{
    //         return true;
    //     }
    // }

    

    this.showModalCarousel = function (data_Set=null, specie_id=null, imageData=null){
        //Actualizo datos de specie detail
        console.log("Cargando specieDetails...");
        specieDetails.specie_id = specie_id; //Cargo a mano el id de la especie...feooo
        specieDetails.getActualSpecieData(data_Set);
        specieDetails.showSpecieDetailBoxDOM(data_Set)//incluye "specieDetails.specieDetailDOMUpdate() y specieDetails.getActualSpecieData(data_Set)"
        let specieName = specieDetails.specieTaxonomy.specie_name
        document.getElementById("specie_name").innerHTML = specieName;
        if(imageData!="0"){
            // //Cargo la respuesta en el imageDataSet y en el muro
            // //Cargo el muro de imágenes
            console.log("Cargando el carousel...");
            carousel.loadCarouselImageData(data_Set, specie_id=specie_id, false)
            //Cargo los elementos de cada imagen en el DOM
            carousel.loadDOMCarousel(true, false, true, specieImagesWall=false, true, true, '/400px-', 11, true);
            //Cargo la imagen activa y sus atributos/////////////////////////
            //Identifico la imagen activa
            carousel.loadActiveImage()//Identifico la imagen activa
            data_Set.actualImageId=carousel.activeImageID//La agrego al dataset actual
            //Cargo todos los atributos del carousel (estrella,notas,blocked y alert)
            if(carousel.activeImageData!=undefined){//Solo si tengo imagen activa
            carousel.loadStarStatus();
            carousel.loadNotesStatus();
            carousel.loadBlockedStatus(document.getElementsByTagName("my-carousel")[0].getElementsByClassName("trash")[0]);
            };
            //SpecieDetails------------------------------
            // console.log("Cargando specieDetails...");
            // specieDetails.specie_id = specie_id; //Cargo a mano el id de la especie...feooo
            // specieDetails.getActualSpecieData(data_Set);
            // // specieDetails.specieDetailDOMUpdate();//Ojoo
            // specieDetails.showSpecieDetailBoxDOM(data_Set)//incluye "specieDetails.specieDetailDOMUpdate() y specieDetails.getActualSpecieData(data_Set)"
            // //Visibilizo el objeto contenedor
            // // let mainImage = document.getElementById("mainImage");
            // // mainImage.setAttribute("style","display:block")
            // //Cargo el nombre de especie y vulgares
            // let specieName = specieDetails.specieTaxonomy.specie_name
            // document.getElementById("specie_name").innerHTML = specieName;
            //Visibilizo carousel si estuviera oculto y viceversa para imagen no encontrada
            document.getElementById("imgNoDisponible").setAttribute("style","display:none")
            document.getElementById("myCarousel").setAttribute("style","display:block")
            //Actualizo estado de botón editar especie/subir imágenes
            document.getElementById("loadActualSpecieImages").setAttribute("style","display:block")
            document.getElementById("loadActualSpecieImages").innerHTML="Ver/editar todas las imágenes";
            //Activo la funcionalidad de image model (para ver la imagen completa onclick)
            carousel.showModalImage();
            //});
              //})
          }else{//Si no hay imágenes disponibles para cargar en el carousel, entro por aquí
            //Reseteo el carousel
            console.log("Limpiando carousel...");
            carousel.resetCarousel()
            // functionsNesting["#miniaturas.specieMode"]["ELSE-imageDataISNOTnull"]={}
            //Pido los datos completos de la especie (los cargo en un nuevo objeto oneSpecieDataSet)

            //SpecieDetails------------------------------
            // specieDetails.getActualSpecieId(carousel)
            // console.log("Cargando specieDetails...");
            // specieDetails.specie_id = specie_id; //Cargo a mano el id de la especie...feooo
            //specieDetails.getActualSpecieData(dataSet)
            //Recargo el DOM de speciedetails
            // specieDetails.getActualSpecieData(data_Set);
            // //En cualquier caso, actualizo el dom completo del specieDetailBox
            // specieDetails.specieDetailDOMUpdate();//Ojoo

            //Oculto carousel (si estubiera visible) y visibilizo imagen no disponible
            //Debería resetear el objeto carousel también
            document.getElementById("myCarousel").setAttribute("style","display:none")
            document.getElementById("imgNoDisponible").setAttribute("style","display:block;height:auto;margin:auto;")
            document.getElementById("loadActualSpecieImages").setAttribute("style","display:block;background-color:red")
            document.getElementById("loadActualSpecieImages").innerHTML="Cargar imágenes";
            //Cierro el área de notas abierta
            document.getElementById("notes").setAttribute("style","display:none");
        
          }

          
          //ABRO EL MODAL
          $("#fullSpecieModal").modal()
        
    }

    //Función para actualizar el texto que indica los filtros activos (específico para la solapa galería del area de estudio)
    this.updateDOMFilterPlainText = function(){
        let listas = user.settings.selectedLists
		let textListas = "<b>Listas: </b>";
		let keysListas=Object.keys(listas)
		if(keysListas.length>0){
			for(let i=0;i<keysListas.length;i++){
				textListas=textListas+listas[keysListas[i]]["name"]+'; '
			}
		}
		document.getElementById("listasActuales").innerHTML=textListas;
		let taxa = user.settings.selectedTaxa
		let textTaxa = "<b>Taxa: </b>";
		let ranks = ["k", "p", "c", "o", "f"]
		for(let j=0; j<ranks.length; j++){
			let textRank = ranks[j];
			let taxon = taxa[textRank]
			if(Object.keys(taxon).length>0){
				textTaxa = textTaxa + textRank+": "
				let keysTaxon=Object.keys(taxon)
				for(let i=0;i<keysTaxon.length;i++){
				textTaxa=textTaxa+taxon[keysTaxon[i]]["text"]+'; '
				}
			}
		}
		document.getElementById("taxaActuales").innerHTML=textTaxa;
    }

        //Método para grabar en base de datos la aprobación de paypal
        this.paypalAproved = function (infoPago, planType){
           return new Promise(function(resolve, reject){
           console.log("Almacenando registro de pago en DB...")
           $.ajax({
               asynch:false,
               type: "POST",
               url: "./A/IPN-paypal.php",
               //url: 'getImageSet',
               //Envío los datos actuales de especies setted (me faltaría favoritos y notas)
               data: { "user_id": user.id,
                        "billingToken":infoPago.billingToken,
                        "facilitatorAccessToken":infoPago.facilitatorAccessToken,
                        "orderID":infoPago.orderID,
                        "paymentID":infoPago.paymentID,
                        "subscriptionID":infoPago.subscriptionID,
                        "planType" : planType
                       },
               success: function(result) {
                   result = JSON.parse(result);
                   resolve(result);
               },
               error: function(result) {
                   alert('Error en el registro automático de pago. Comuniquese con nosostros para que lo solucionemos a la brevedad');
                   reject();
               }
           });    
           });
       };


      //Método para manejar el modal de paypal según estado del usuario
        this.paypalStatus = function (resultado){
            console.log("Evaluando paypalStatus")
            if(resultado["logged"]==1){//USUARIO LOGUEADO
                //Cargo los datos del usuario en "user"
                // console.log("Cargando datos de usuario\n")
                // user.loadUserData(resultado);
                //user.actualPage="test";//Podría llegar a cambia
                //Verifico el estado de premium (o que se hayan pedido hasta dos tandas de imágenes nuevas)
                // if(resultado["premium"]==1 || tandaNumero < 2){
                if(resultado["premium"]==1 || resultado["answersCounter"] < 100){
                    //Oculto modal de paypal
                    $("#paypalModal").modal("hide");
                }else if(resultado["premium"]!=1){//Si no tiene cuenta premium activa, puede ser por varios motivos
                    //Borro cualquier botón previo renderizado
                    $("#paypal-button-container-x").empty()
                    //Renderizo el botón de paypal y cargo las funcionalidades correspondientes
                    //El plan a utilizar dependerá de si el usuario ya ha tenido un plan previo
                    if(resultado["premium"]==0){//Nunca hubo cuenta premium
                        // cargo el plan csp
                        var planType = 0;
                        // var plan_id = 'P-2LN70237T22518324MBNOPTA';//SANDBOX
                        // var plan_id = 'P-5EN04739PT662122EMBMO6FQ';//LIVE 6,6€/mes con periodo de prueba
                        var plan_id = 'P-7KM752053G2196716MDOZYMA'//LIVE 2€/mes con periodo de prueba
                        $("#textAnexoToPayPal").text("(1 semana de prueba gratuita)")
                        // $("#c-semana-prueba").show();
                        // $("#s-semana-prueba").hide();
                    }else if(resultado["premium"]==2){//Cuenta pausada
                        alert("Su suscripción se encuentra actualmente en pausa. Solicite su reactivación desde \'mail>Suscripciones\' o comuníquese con nosotros para que lo hagamos por usted.")
                        // cargo el plan ssp
                        var planType = 1;
                        // var plan_id = 'P-1MM7515102594632AMBROYBQ';//SANDBOX
                        // var plan_id = 'P-6CX344158T049810RMBQ7MJI'; //LIVE 6,6€/mes sin periodo de prueba
                        var plan_id = 'P-27W3166546813343LMBSGCHI';//LIVE 2€/mes sin periodo de prueba
                        $("#textAnexoToPayPal").text("(semana de prueba no incluída)")
                        // $("#c-semana-prueba").hide();
                        // $("#s-semana-prueba").show();
                    }else if (resultado["premium"]==3){//Cuenta cancelada
                        alert("Su suscripción se encuentra actualmente cancelada. Deberá realizar una nueva suscripción.")
                        // cargo el plan ssp
                        var planType = 1;
                        // var plan_id = 'P-1MM7515102594632AMBROYBQ'; //SANDBOX
                        // var plan_id = 'P-6CX344158T049810RMBQ7MJI' //LIVE 6,6€/mes sin periodo de prueba
                        var plan_id = 'P-27W3166546813343LMBSGCHI';//LIVE 2€/mes sin periodo de prueba
                        $("#textAnexoToPayPal").text("(semana de prueba no incluída)")
                        // $("#c-semana-prueba").hide();
                        // $("#s-semana-prueba").show();
                    }
                    paypal.Buttons({
                        style: {
                            shape: 'rect',
                            color: 'gold',
                            layout: 'vertical',
                            label: 'subscribe'
                        },
                        createSubscription: function(data, actions) {
                        return actions.subscription.create({
                            /* Creates the subscription */
                            plan_id: plan_id
                        });
                        },
                        onApprove: function(data, actions) {
                        //alert(data.subscriptionID); // You can add optional success message for the subscriber here
                        //console.log(data);
                        //Custom
                        gf.paypalAproved(data, planType).then(function(data){
                            if(data===1){
                                alert("Registro de pago exitoso.")
                                user.checkSession()
                                    .then(function(resultado){
                                        navBar.navBarSessionStatus(resultado);
                                        if(resultado["logged"]==1){//USUARIO LOGUEADO
                                            user.loadUserData(resultado);
                                            // user.actualPage="index";
                                        }
                                    });
                            }else if(data===0){
                                alert("Ha ocurrido un error en el almacenamiento del pago. Comuníquese con nosotros (estudiavisu) para solucionarlo")
                            }else{
                                alert(data)
                            }
                            //Oculto modal de paypal
                            $("#paypalModal").modal("hide");
                        })
                        }
                    }).render('#paypal-button-container-x'); // Renders the PayPal button

                    $("#paypalModal").modal('show');
                }
            }else{
                //Oculto modal de paypal
                $("#paypalModal").modal("hide");
            }
       };

       //Metodo para obtener información de suscripción del usuario
       this.suscriptionsDetails = function(){
           console.log("Preparando modal de datos de suscripción")
           user.getSuscriptionDetails().then(function(data){
               //Agrego información al userDetailsModal
               $("#userDetailsModal #userEmail").text(user.email);
               //Cargo la tabla con las suscripciones
               gf.loadSuscriptionsTable(data);
               //Abro el modal
               $("#userDetailsModal").modal();//Abro modal
           })
       }

       this.loadSuscriptionsTable = function(data){
           console.log("Cargando datos de suscripción en la tabla")
        var suscriptions = Object.keys(data);
        if(suscriptions.length>0){//Si tengo al menos 1 suscripción, creo la tabla
         var tableBody=document.getElementById("suscriptionsDataTable").getElementsByTagName("tbody")[1];
         gf.deleteChild(tableBody)//Borro todas las filas de datos
         for(let i = 0; i<suscriptions.length; i++){
             let plan = data[suscriptions[i]].plan
             let costo = data[suscriptions[i]].costo
             let subscriptionID = data[suscriptions[i]].subscriptionID
             let status = data[suscriptions[i]].status
             let reactivateButton = '<div class="suscriptionReactive pointer" value="'+subscriptionID+'" style="padding: 5px;border: solid 1px grey;border-radius: 5px;background-color: green;text-align: center;">Reactivar</div>'
             let pauseButton = '<div class="suscriptionPause pointer" value="'+subscriptionID+'" style="padding: 5px;border: solid 1px grey;border-radius: 5px;background-color: yellow;text-align: center;">Pausar</div>'
             let cancelButton ='<div class="suscriptionCancel pointer" value="'+subscriptionID+'" style="padding: 5px;border: solid 1px grey;border-radius: 5px;background-color: red;text-align: center;">Cancelar</div>'
             let buttons = /*pauseButton+*/cancelButton;
             if(status==0){
                 var statusColor = 'green';
                 status = "Periodo de prueba"
             }else if(status==1){
                 var statusColor = 'green';
                 status = "Activa"
             }else if(status==2){
                 var statusColor = 'grey';
                 status = "Pausada"
                 buttons = reactivateButton+cancelButton;
             }else if(status==3){
                 var statusColor = 'red';
                 status = "Cancelada"
                 buttons = '-'
             }else if(status==-1){
                 status = "Solicitud de reactivación pendiente"
                 var statusColor = '#ccff00';
                 buttons = /*pauseButton+*/cancelButton;
             }else if(status==-2){
                 status = "Solicitud de pausa pendiente"
                 var statusColor = '#ffd400d4';
                 buttons = reactivateButton+cancelButton;
             }else if(status==-3){
                 status = "Solicitud de cancelación pendiente"
                 var statusColor = '#ffc107';
                 buttons = reactivateButton/*+pauseButton*/;
             }else{
                 var statusColor = 'white';
             }
             let created_at = data[suscriptions[i]].created_at
             //Relleno con los datos
             childRow=document.createElement("tr")
             $(childRow).attr("suscriptionID",subscriptionID)
             childRow.innerHTML='<td>'+plan+'</td>\
             <td>'+costo+'</td>\
             <td>'+subscriptionID+'</td>\
             <td title="Fecha de creación original de la suscripción. El momento de acreditación de cada pago podría variar si se ha pausado en algún momento">'+created_at+'</td>\
             <td style="background-color:'+statusColor+'">'+status+'</td>\
             <td>'+buttons+'</td>'
             tableBody.appendChild(childRow);
         }
        }else{//Si no hay suscripciones
         var tableBody=document.getElementById("suscriptionsDataTable").getElementsByTagName("tbody")[1];
         gf.deleteChild(tableBody)//Borro todas las filas de datos
        }
       }

}
