//defino la clase
export class Navbars extends HTMLElement {
    // Implementación del componente
    constructor() {
        super();
		// Defino un método que cambiará el color del navbar y algunas opciones según ele stado del usuario
    }
    connectedCallback () {
        // let shadowRoot = this.attachShadow({mode: 'open'});
		// shadowRoot.innerHTML = `
		console.log("cargando elemento navbar")
		this.innerHTML = 
		`
		<nav id="navbar" class="navbar navbar-expand-sm bg-gray navbar-dark justify-content-start smart-scroll" style="background-color: grey; display:none;">
			 
			<ul id="dropdownIrA" class="navbar-nav text-left">
				<a href="#" class="nav-link dropdown-toggle" data-toggle="dropdown" style="color: white;">Ir a...</a>
					<div class="dropdown-menu" style="position: absolute;">
						<a id="testArea" href="./practicar-visu.html" class="dropdown-item" title="Permite practicar con grupos de imágenes de distintas especies (según los filtros aplicados)">Área de práctica</a>
						<a id="studyArea" href="./estudiar-visu.html" class="dropdown-item" title="Permite estudiar utilizando todas las imágenes/especies disponibles)">Área de estudio</a>
						<a id="infoOposiciones" href="./info/oposiciones-secundaria-descripcion-general.html" target='_blank' class="dropdown-item" title="Información sobre la modalidad de evaluación de las oposiciones" >¿Cómo son las oposiciones?</a>
						<a id="consejos" href="./info/consejos-estudiar-visu.html" target='_blank' class="dropdown-item" title="Consejos para preparar el visu utilizando estudiavisu" >Consejos para preparar el visu</a>
						<a id="tutorialsButton" href="./info/tutoriales.html" target='_blank' class="dropdown-item tutorialModal" title="Información de las distintas opciones disponibles" >Tutoriales</a>
						<a href="../contactForm.html" target="_blank" class="dropdown-item" title="Póngase en contacto con nosotros">Contacto</a>
					</div>
			</ul>

			<a class="navbar-brand ml-auto mr-auto" href="./"><img id="navbarBrand" src="./img/icons/ev.svg" width="30" height="30" alt="">
			<img id="navbarBrand" src="./img/icons/picoYPala.png" width="30" height="30" alt="" title="Estamos haciendo algunos cambios en la página por lo cual puede experimentar algunas dificultades.">
			</a>

			<ul id="user" class="navbar-nav collapse ml-auto mr-1 session" style="display:none; margin-left: 0px!important;">
				<a id="UsuarioNavbar" href="#" class="nav-link dropdown-toggle" data-toggle="dropdown" style="color: white;">Usuario</a>
					<div class="dropdown-menu dropdown-menu-right" style="position: absolute; text-align: center;min-width: 5rem;">
						<a id="customLogout" href="#" class="dropdown-item session"><b>Salir</b></a>
						<a id="administrarSuscripciones" href="#" class="dropdown-item session">Suscripciones</a>
					</div>
			</ul>
			<button  href="#" class="btn btn-primary ml-auto mr-1 noSession showLogIn" style="display:block; margin-left: 0px!important;">Entrar</button>
		</nav>
	        `;
          }
}

//defino el elemento custom (etiqueta, clase)
window.customElements.define("my-navbar",Navbars)

//Escucho un click en cualquier ".dropdown-item" y colapso la hamburguesa
$('#navbar').on('click', '.dropdown-item', function(){
	$("#navbarSupportedContent").removeClass("show")
});

//Escucho el botón de logueo (y registro si corresponde)
//Entrar/Registrarse
$('#navbar').on('click', '.showLogIn',function (e) {
	console.log("Abriendo modal de logueo")
	// $("#signIn_registry").toggle();
	$("#signIn").show();
	$("#registry").hide();
	$("#loginModal").modal('toggle')
});
//Registrarse
// $('.showRegistry').on('click', function (e) {
//     event.preventDefault();//evito la redireccion a la nueva página (temporal)
//     document.getElementById("signIn_registry").setAttribute("style", "display:block");
//     document.getElementById("signIn").setAttribute("style", "display:none")
//     document.getElementById("registry").setAttribute("style", "display:block")
// });

//1) ABRO EL FILTER BOX Y CARGO TODA LA INFO
$('#navbar').on('click', '#showFilters', function (e) {
	filters.showFilters();
});


//logout
$("#customLogout").click(function(e){
    navBar.logout();
});

//Abro modal administración de suscripciones
$("#administrarSuscripciones").click(function(e){
	gf.suscriptionsDetails();//Actualizo datos de suscripciones y abro el modal
});

// detect scroll top or down
if ($('.smart-scroll').length > 0) { // check if element exists
	var last_scroll_top = 0;
	let scroll_top;
    $(window).on('scroll', function() {
        scroll_top = $(this).scrollTop();
        if(scroll_top < last_scroll_top) {
            $('.smart-scroll').removeClass('scrolled-down').addClass('scrolled-up');
        }
        else {
            $('.smart-scroll').removeClass('scrolled-up').addClass('scrolled-down');
        }
        last_scroll_top = scroll_top;
    });
}

$('#navbar').on('click', '.tutorialModal', function (e) {
	$("#graphicTutorialModal").modal()
});

