

//import DropdownMenu from '../Classes/classDropdownMenu';

//defino la clase
class ModalLogin extends HTMLElement {
    // Implementación del componente
    constructor() {
        super();
    }
    connectedCallback () {
    console.log("cargando login modal")
    // let shadowRoot = this.attachShadow({mode: 'open'});
    // shadowRoot.innerHTML = `

    this.innerHTML = `
        <div id="loginModal" class="modal fade" data-keyboard="false" data-backdrop="static" style="display:none">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">						
              <h3 class="modal-title" style="height: 1rem;"></h3>
              <button type="button" class="close" data-dismiss="modal" aria-hidden="true" style="padding-top: 0.8rem;">X</button>
            </div>
              <my-login></my-login>
              <my-registry></my-registry>
            </div>
        </div>
      </div>
      `;
        }
}

//defino el elemento custom (etiqueta, clase)
window.customElements.define("my-loginmodal",ModalLogin)

