const general_functions = require("../general_functions");

//defino la clase
class RestoreKey extends HTMLElement {
    // Implementación del componente
    constructor() {
        super();
        //const template = document.createElement('template');
        //this.innerHTML = `<h1>Hola mundo!!!</h1>`;
    }
    connectedCallback () {
		console.log("cargando elemento registry")

        // let shadowRoot = this.attachShadow({mode: 'open'});
        // shadowRoot.innerHTML = `
		this.innerHTML = `
		<div id="restoreKeyModal" class="modal fade" data-keyboard="false" data-backdrop="static" style="display:none">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header" style="padding-bottom: 5px;padding-top: 5px;">						
              <h3 class="modal-title" style="padding-bottom: 0px;margin-bottom: 3px">Recuperacion de contraseña</h3>
              <!--<button type="button" class="close" data-dismiss="modal" aria-hidden="true" style="padding-top: 0.8rem;">X</button>-->
            </div>
			  
			<div id="restoreKey" class="container" style="display:block">
			<!--<div class="row">
				<div class="col-sm-12">
					<h1>Recuperacion de contraseña</h1>		
				</div>
			</div>-->
		
			<div class="row">	
				<div class="col-12" style="padding-bottom: 15px;">
					<form id="restoreKeyForm" name="restoreKeyForm" action="" method="post">
						<div class="form-group">				
							<input id=restoreKeyemail type="restoreKeyemail" class="form-control" name="email" maxlength="127" aria-describedby="emailHelp" placeholder="Correo electrónico" disabled = "disabled" required>
						</div>
						<div class="form-group">				
							<input id="restoreKeypassword" type="password" class="form-control" name="password" maxlength="20" placeholder="Elija una contraseña (6 a 20 caracteres)" required>
							<span id='passwordMessage'></span>
						</div>
						<div class="form-group">				
						<input id="restoreKeypasswordConfirm" type="password" class="form-control" name="passwordConfirm" maxlength="20" placeholder="Repita la contraseña" required>
						<span id='restoreKeypasswordConfirmMessage'></span>
						</div>
						<button id="restoreKeySend" type="submit" class="btn btn-success btn-block"
						style ="color:black">Reestablecer contraseña</button>
					</form>		
				</div>		
			</div>
		</div>

            </div>
        </div>
      </div>
        
            `;
        }
}

//defino el elemento custom (etiqueta, clase)
window.customElements.define("my-restorekeymodal",RestoreKey)


//Envío información de registro
$("my-restoreKeyModal").on('click',"#restoreKeySend",function(e){
    e.preventDefault();
    //var form = $("form")
	var form = $("#restoreKeyForm");
	//var form[0].email.value=params.id;
	let validation = gf.validateLogingRegistryForm(form[0])
	if(validation){
		//Evaluo coincidencia password/confirmación password
		if($('#restoreKeypassword').val() != $('#restoreKeypasswordConfirm').val()){
            alert("La clave ingresada no coincide con la confirmación"); 
            $('#restoreKeypasswordConfirmMessage').html('Las contraseñas ingresadas no coinciden').css('color', 'red');
            return false; 
		}
		gf.newKey(form[0])
		.then(function(resultado){
			// if(resultado["registry"]=="1"){
			// 	alert("Restauración de contraseña exitosa");
			// 	//Oculto div de logueo/registro
			// 	// document.getElementById("signIn_registry").setAttribute("style", "display:none");
			// }else if(resultado["registry"]=="2"){
			// 	alert("El mail ya se encuentra registrado y validado. Intente ingresar. \
			// 	Si continúan los problemas, comuníquese con el administrador");
			// }else{
			// 	alert("Hubo algún problema en el registro del usuario");
			// }
			alert((resultado["mensaje"]))
			//Por ahora recargo la página completa
			window.location.replace("./index.html");
			//Reseteo formulario de registro
			// $('#registryForm').trigger("reset");
			// document.getElementById('createAccount').disabled = "disabled";
			// $("#restoreKeyModal").modal('hide')

		});
	}
});