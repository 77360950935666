const general_functions = require("../general_functions");

//defino la clase
class Registry extends HTMLElement {
    // Implementación del componente
    constructor() {
        super();
        //const template = document.createElement('template');
        //this.innerHTML = `<h1>Hola mundo!!!</h1>`;
    }
    connectedCallback () {
		console.log("cargando elemento registry")

        // let shadowRoot = this.attachShadow({mode: 'open'});
        // shadowRoot.innerHTML = `
        this.innerHTML = `
        <div id="registry" class="container" style="display:none">
			<!--<div class="row">
				<div class="col-sm-12">
					<h1>Formulario de registro</h1>		
				</div>
			</div>-->
		
			<div class="row">	
				<!--<div class="col-sm-12 col-md-6 col-lg-6">-->
				<div class="col-12">
					<h3>Formulario de registro</h3>
					<form id="registryForm" name="registryForm" action="" method="post">
						<div class="form-group">				
							<input id=email type="email" class="form-control" name="email" maxlength="127" aria-describedby="emailHelp" placeholder="Correo electrónico" required>
						</div>
						<div class="form-group">				
							<input id="password" type="password" class="form-control" name="password" maxlength="20" placeholder="Elija una contraseña (6 a 20 caracteres)" required>
							<span id='passwordMessage'></span>
						</div>
						<div class="form-group">				
						<input id="passwordConfirm" type="password" class="form-control" name="passwordConfirm" maxlength="20" placeholder="Repita la contraseña" required>
						<span id='passwordConfirmMessage'></span>
						</div>
						<div class="form-group">
								<input id="checkedChanged" type="checkbox" value="checkbox"/> 
								<p>Acepto los <a id="termsAndConditions" href="#">terminos y condiciones</a></p>
						</div>
						<button id="createAccount" type="submit" class="btn btn-success btn-block" disabled = "disabled"
						style ="color:black">Crear cuenta</button>
					</form>		
				</div>		
				<!--<div class="col-sm-12 col-md-6 col-lg-6">-->
				<div class="col-12">
					<div id=registration_status></div>
					<!--<h3>Entrar</h3>-->
					<hr/>
					<p>¿Ya está registrado? <a class="showLogIn" href="#" title="Ingrese con este enlace">Entrar</a></p>
				</div>
			</div>
		</div>
            `;
        }
}

//defino el elemento custom (etiqueta, clase)
window.customElements.define("my-registry",Registry)

// $('#registry').on('click', '.showLogIn',function (e) {
$('my-loginmodal').on('click', '.showLogIn',function (e) {
	$("#registry").hide();
	$("#signIn").show();
});

// $("#checkedChanged").click(function(){
$("my-loginmodal").on('click','#checkedChanged',function(){
		console.log("condiciones aceptadas:" +this.checked);
    var button = document.getElementById('createAccount');
    if (this.checked == true) {
        button.disabled = "";
        button.style.color = "black";
        button.style.backgroundColor="green";
    } else {
        button.disabled = "disabled";
        button.style.color = "black";
    };
});

//Abro ventana emergente con términos y condiciones
$("my-loginmodal").on('click','#termsAndConditions',function(){
	var wOR = null; // global variable
	var PreviousUrl; /* global variable which will store the
					url currently in the secondary window */
	gf.openRequestedSinglePopup(wOR, PreviousUrl, "./terms.html","TerminosYCondicones");// return false;
});

//Envío información de registro
$("my-loginmodal").on('click',"#createAccount",function(e){
    e.preventDefault();
    //var form = $("form")
	var form = $("#registryForm");
	let validation = gf.validateLogingRegistryForm(form[0])
	if(validation){
		//Evaluo coincidencia password/confirmación password
		if($('#password').val() != $('#passwordConfirm').val()){
            alert("La clave ingresada no coincide con la confirmación"); 
            $('#passwordConfirmMessage').html('Las contraseñas ingresadas no coinciden').css('color', 'red');
            return false; 
        }
		navBar.registry(form)
		.then(function(resultado){
			if(resultado["registry"]=="1"){
				alert("Registrado con éxito, revisa tu mail para activar la cuenta (recuerda revisar la carpeta de 'spam')");
				//Oculto div de logueo/registro
				// document.getElementById("signIn_registry").setAttribute("style", "display:none");
			}else if(resultado["registry"]=="2"){
				alert("El mail ya se encuentra registrado y validado. Intente ingresar. \
				Si continúan los problemas, comuníquese con el administrador");
			}else{
				alert("Hubo algún problema en el registro del usuario");
			}
			//Reseteo formulario de registro
			$('#registryForm').trigger("reset");
			document.getElementById('createAccount').disabled = "disabled";
			$("#loginModal").modal('hide')

		});
	}
});