//defino la clase
export class Footers extends HTMLElement {
    // Implementación del componente
    constructor() {
        super();
		// Defino un método que cambiará el color del navbar y algunas opciones según ele stado del usuario
    }
    connectedCallback () {
        // let shadowRoot = this.attachShadow({mode: 'open'});
		// shadowRoot.innerHTML = `
		console.log("cargando elemento navbar")
		this.innerHTML = `
		<footer class="col-12" style="margin-top: 20px;">
			<div class="row" style="background-color: rgba(0, 0, 0, 0.2)">
				<div class="col-4"> 
					<!-- <a class="text-dark" href="https://mdbootstrap.com/">Novedades</a> -->
					<a href="#" onclick="window.open('./avisos.html', 'Avisos', 'width=600,height=900');"  title="Avisos y novedades" >
						<span class="material-icons" style="vertical-align: middle; color:red">
						warning_amber
						</span>
						<b>Avisos</b>
					</a>
				</div>
				<div class="col-4"> 
					<a href="#" onclick="window.open('./contactForm.html', 'Contacto');"  title="Póngase en contacto con nosotros">Contacto</a>
				</div>
				<div class="col-4"> 
					<a href="#" onclick="window.open('./terms.html', 'Términos y Condiciones', 'width=600,height=900');"  title="Términos y Condiciones">TyC</a>
				</div>
			</div>
			<!-- <div class="row" style="background-color: rgba(0, 0, 0, 0.2)">
				<div class="col-8 alert-row"> 
					<div><b>Atención:</b> esta es una versión en desarrollo. Para más detalles pinche <a href="#" onclick="window.open('./avisos.html');">aquí</a> </div>
				</div>
			</div> -->
		  </footer>
		`;
          }
}

//defino el elemento custom (etiqueta, clase)
window.customElements.define("my-navfooter",Footers)



